import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import galleryImage from "../images/gallery.jpg"
import Gallery from "@browniebroke/gatsby-image-gallery"

import "./gallery.css"

const ImageWrapper = ({ children, onClick }) => (
  <div
    className="image-wrapper"
    onClick={onClick}
    onKeyDown={onClick}
    aria-hidden="true"
  >
    {children}
  </div>
)

const GalleryPage = ({ data }) => {
  const images = data.images.edges.map(({ node }) => ({
    ...node.childImageSharp,
  }))

  return (
    <Layout imageUrl={galleryImage} pageTitle="Gallery">
      <div className="gallery-container">
        <Gallery images={images} colWidth="50" customWrapper={ImageWrapper} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
export default GalleryPage
